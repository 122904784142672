@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.ant-btn-primary {
  background: #3874f6 !important;
}

.ant-btn-primary:hover {
  background: #4785f7 !important;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  border-top: 1px solid #f0f0f0;
  border-bottom: none !important;
}

.ant-table-wrapper .ant-table .ant-table-row {
  background-color: white !important;
}

.ant-table-wrapper .ant-table .ant-table-header {
  background-color: rgba(255, 255, 255, 0.4) !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: rgba(0, 0, 0, 0.45) !important;
}

.ant-table-placeholder {
  background-color: white !important;
  height: 390px !important;
}

.nav-select .ant-select-selector {
  background: none !important;
  border: none !important;
}

.ant-input-wrapper .ant-input .ant-btn {
  border-radius: 3px !important;
}

.ant-table-expanded-row .ant-table-cell {
  padding: 0 !important;
}
.ant-table-expanded-row .ant-table-content .ant-table-cell {
  padding: 16px !important;
}

/* TODO: Add .pop class when id comparison is working correctly */
.highlight.hl {
  background: rgba(255, 255, 153, 0.4);
}
.comment.hl {
  background: rgba(152, 203, 152, 0.4);
}
.event.hl {
  background: rgba(202, 202, 243, 0.4);
}
.evidence.hl {
  background: rgba(245, 100, 245, 0.4);
}

nuclia-search-results {
  display: inline;
}

.tremor-Legend-root > div {
  flex-direction: column;
  gap: 8px !important;
  max-height: 200px;
}

.tremor-Legend-legendItem > svg {
  width: 12px;
}

@media (min-width: 100px) {
  .tremor-Legend-root {
    font-size: 100px !important;
  }
}

.recharts-layer.recharts-pie.stroke-tremor-background.dark\:stroke-dark-tremor-background {
  stroke: #fff !important;
}

.stroke-1.stroke-tremor-border.dark\:stroke-dark-tremor-border {
  stroke: #e5e7eb !important;
}

.ant-collapse-content-box {
  background-color: white !important;
}
.ant-table-filter-dropdown {
  background-color: white !important;
}

.ant-menu-submenu-title {
  color: black !important;
}

.ant-timeline-item-label {
  width: fit-content !important;
}

.ant-timeline-item-tail,
.ant-timeline-item-head {
  inset-inline-start: calc(7% - 5px) !important;
  border-style: dashed !important;
}

.ant-timeline-item-content {
  padding-top: 5px !important;
  inset-inline-start: 7% !important;
  width: calc(92%) !important;
}

.ant-timeline-item-head-custom {
  background-color: transparent !important;
}

@media only screen and (max-width: 1900px) {
  .ant-timeline-item-tail,
  .ant-timeline-item-head {
    inset-inline-start: calc(10% - 5px) !important;
  }

  .ant-timeline-item-content {
    padding-top: 5px !important;
    inset-inline-start: 10% !important;
    width: calc(88.5%) !important;
  }
}

.ant-switch {
  background-color: #7e8388;
}

.ant-switch-checked {
  background-color: blue;
}

td.ant-table-column-sort,
th.ant-table-column-sort {
  background-color: transparent !important;
}

td.ant-table-column-sort.ant-table-cell-row-hover {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.filters-bar {
  background-color: rgba(255, 255, 255, 0.4) !important;
  box-shadow: 0 2px 3px rgba(107, 107, 107, 0.1) !important;
}

.document-viewer .ant-collapse-content-box {
  padding: 0 !important;
}

.tag-button {
  color: white !important;
}
